$(document).ready(function () {
	//Hide element when smaller than 1025
	if ($(window).width() < 1200.1) {
		$(".nav-primary-menu").fadeIn(function () {
			$(".nav-primary-menu").css({
				display: "flex",
			});
		});
		$(".searchbox").fadeIn(function () {
			$(".searchbox").css({
				display: "flex",
			});
		});
		$(".header-booking-wrap").fadeIn(function () {
			$(".header-booking").css({
				display: "flex",
			});
		});
	}
	counterJs();
	setBackground();
	swiperInit();
	toggleFunc();
	tabFunc();
	mappingFunc();
	iframeRatio();
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});
window.onscroll = function () {
	if (
		document.body.scrollTop > $(window).height() ||
		document.documentElement.scrollTop > $(window).height()
	) {
		$(".side-nav-tool").addClass("show");
	} else {
		$(".side-nav-tool").removeClass("show");
	}
};

function toggleFunc() {
	document
		.querySelector(".menu-toggle")
		.addEventListener("click", function (event) {
			document
				.querySelector(".mobile-nav-wrap")
				.classList.toggle("is-open");
			document.querySelector("body").classList.toggle("overlay-bg");
			document.querySelector(".hamburger").classList.toggle("is-active");
		});
	document
		.querySelector(".search-toggle")
		.addEventListener("click", function (event) {
			document.querySelector(".searchbox").classList.toggle("open");
		});
	document.addEventListener("click", function (event) {
		if (
			!event.target.closest(".search-toggle") &&
			!event.target.closest(".searchbox")
		) {
			document.querySelector(".searchbox").classList.remove("open");
		}
	});
	document.addEventListener("click", function (event) {
		if (
			!event.target.closest(".mobile-nav-wrap") &&
			!event.target.closest(".menu-toggle")
		) {
			document
				.querySelector(".mobile-nav-wrap")
				.classList.remove("is-open");
			document.querySelector("body").classList.remove("overlay-bg");
		}
	});
	if ($(window).width() < 1200) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("is-open")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("is-open");
			}
		});
	}
}
function iframeRatio() {
	if ($("body").hasClass("news-detail-page")) {
		if ($(".news-detail .fullcontent iframe").length) {
			$(".news-detail .fullcontent iframe").wrap(
				"<div class='iframe-ratio'></div>"
			);
		}
	}
}
function swiperInit() {
	$(".trinity-swiper .swiper").each(function (index, element) {
		const $element = $(element);
		const parent = $element.parent();
		const prev = parent.find(".nav-prev");
		const next = parent.find(".nav-next");
		const options = {
			speed: 750,
			autoplay: {
				delay: 3000,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: next,
				prevEl: prev,
			},
		};
		$element.addClass(`t-${index}`);
		prev.addClass(`bp-${index}`);
		next.addClass(`bn-${index}`);
		new Swiper(`.t-${index}`, {
			...options,
			navigation: {
				...options.navigation,
				nextEl: `.bn-${index}`,
				prevEl: `.bp-${index}`,
			},
		});
	});
	var topBanner = new Swiper(".top-banner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
		.parent()
		.parent()
		.find(".swiper-pagination")
		.addClass("pagination-single-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-sync-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-sync-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 900,
			autoplay: {
				delay: 4000,
			},
			lazy: {
				loadPrevNext: true,
			},
			spaceBetween: 20,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-sync-" + index,
				prevEl: ".btn-prev-sync-" + index,
			},
			pagination: {
				el: ".pagination-single-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".grid-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-grid-" + index);
		$this
			.parent()
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-grid-" + index);
		var swiper = new Swiper(".single-grid-" + index, {
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 4,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 4,
					grid: {
						rows: 2,
					},
				},
			},
			pagination: {
				el: ".pagination-grid-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});

	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},

		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
	var primarySwiper = new Swiper(".home-four-swiper  .swiper", {
		// Optional parameters
		breakpoints: {
			200: {
				slidesPerView: 1,
			},
			576: {
				slidesPerView: 2,
			},
			769: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4,
			},
		},
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		spaceBetween: 30,
		navigation: {
			nextEl: ".home-four-swiper .nav-next",
			prevEl: ".home-four-swiper .nav-prev",
		},
	});
}

//Tab Func
function tabFunc() {
	$(".tab-wrapper .tab-item:first-child").addClass("active");
	$(".tab-nav li a").on("click", function () {
		$(this).parents(".tab-nav").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}
// Side
function setBackground() {
	const elements = document.querySelectorAll("[setBackground]");
	elements.forEach((element) => {
		element.style.cssText = `
		background-image: url(${element.getAttribute("setBackground")});
		background-size: cover;
		background-position: center;
	  `;
	});
}

function counterJs() {
	const counterUp = window.counterUp.default;

	const callback = (entries) => {
		entries.forEach((entry) => {
			const el = entry.target;
			if (entry.isIntersecting && !el.classList.contains("is-visible")) {
				counterUp(el, {
					duration: 2000,
					delay: 50,
				});
				el.classList.add("is-visible");
			}
		});
	};

	const IO = new IntersectionObserver(callback, { threshold: 1 });

	const el = document.querySelectorAll(".counter");

	el.forEach((counter) => {
		IO.observe(counter);
	});
}

function mappingFunc() {
	new MappingListener({
		selector: ".searchbox",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-wrapper",
		desktopMethod: "appendTo",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".nav-primary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-wrapper",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".header-booking-wrap",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".language-wrapper",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();
}
